import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer__container">
        <p>Copyright &copy; 2022 Eden. Tous droits réservés.</p>
        <p>Politique de confidentialité</p>
      </div>
    </footer>
  );
};

export default Footer;
