import React from 'react';
import PreviewImg from '../assets/images/offer-preview.svg';

const PreviewBlock = () => {
  return (
    <div className="preview">
      <img src={PreviewImg} alt="Preview" />
    </div>
  );
};

export default PreviewBlock;
