import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Offer from "./pages/Offer";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Offer />,
  },
  {
    path: "/:offerId",
    element: <Offer />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
