import React, {useEffect, useState} from 'react';

const InfoBlock = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [discountPercent, setDiscountPercent] = useState();
  const [title, setTitle] = useState();
  const [productUrl, setProductUrl] = useState();
  const [bestOptionUrl, setBestOptionUrl] = useState();
  const [couponCode, setCouponCode] = useState();
  const [domain, setDomain] = useState();
  const [giftcardPercent, setGiftcardPercent] = useState();
  const [cashbackPercent, setCashbackPercent] = useState();
  useEffect(() => {
    fetch(`https://api-gateway.franceverif.fr/d/${window.location.href.split('/').at(-1)}`)
      .then((response) => response.json())
      .then((response) => {
        setIsLoading(false);
        if (response['code'] === 404) {
          alert(response['message'] + '!');
          setIsError(true);
        } else {
          setDiscountPercent(response['discountPercent']);
          setTitle(response['title']);
          setProductUrl(response['productUrl']);
          setBestOptionUrl(response['bestOptionUrl']);
          setCouponCode(response['couponCode']);
          setDomain(response['domain']);
          setGiftcardPercent(response['giftcardPercent']);
          setCashbackPercent(response['cashbackPercent']);
        }
      });
  }, []);
  return (
    <div className="info">
      <div className="info__inner">
        <div className="info__logo">
          <svg width="108" height="48" viewBox="0 0 108 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M26.0248 20.7205C25.8315 20.9752 23.6493 22.4049 19.4783 25.0072C15.3047 27.6119 11.9297 29.7124 9.35334 31.3086C10.2562 33.7671 11.5762 35.7314 13.3158 37.2015C15.0554 38.6716 16.8765 39.4054 18.7763 39.4054C19.9691 39.4054 20.8796 39.365 21.5078 39.2843C22.136 39.2062 22.7719 38.9969 23.4153 38.6615C24.0588 38.3261 24.7684 37.7764 25.5415 37.0099L26.0248 37.489C25.4449 38.0639 24.8726 38.5758 24.3106 39.0221C23.746 39.4684 22.6828 39.9651 21.1213 40.5073C19.5571 41.0494 17.6496 41.3218 15.3937 41.3218C12.2375 41.3218 9.65091 40.7544 7.63915 39.6197C5.62484 38.4875 4.15989 36.9771 3.24176 35.0935C2.32362 33.2098 1.86328 31.1321 1.86328 28.8652C1.86328 26.4066 2.37194 24.0817 3.38673 21.893C4.40151 19.7068 5.9631 17.9266 8.07405 16.5523C10.1825 15.1781 12.7843 14.4922 15.8769 14.4922C18.4533 14.4922 20.5312 14.9309 22.1106 15.8085C23.69 16.6885 24.7938 17.6542 25.422 18.7083C26.0502 19.7623 26.2511 20.433 26.0248 20.7205ZM19.2595 20.433C19.2595 19.2832 18.8984 18.1661 18.1736 17.0793C17.4487 15.9925 16.1999 15.4504 14.4273 15.4504C12.7207 15.4504 11.2456 16.1615 10.007 17.5811C8.76583 19.0033 8.14527 21.3282 8.14527 24.5533C8.14527 26.5025 8.41994 28.3709 8.96676 30.1587C11.4796 28.8803 13.8245 27.3875 15.999 25.6779C18.1736 23.9708 19.2595 22.2208 19.2595 20.433Z"
              fill="#70B2CE"/>
            <path
              d="M54.5342 6.34766C54.5342 6.47626 54.374 8.24892 54.051 11.6657C53.728 15.0824 53.5677 19.2203 53.5677 24.0743C53.5677 26.6287 53.6389 29.0721 53.7839 31.4046C53.9289 33.737 54.0738 35.6358 54.2188 37.1058C54.3638 38.5759 54.4706 39.5013 54.5342 39.8846H47.2857L47.3824 37.8724C46.7695 38.6717 45.9734 39.3652 44.9891 39.9552C44.0074 40.5478 42.84 40.8428 41.487 40.8428C39.8771 40.8428 38.1374 40.452 36.2681 39.6678C34.3988 38.8861 32.7889 37.5698 31.4358 35.7165C30.0828 33.8631 29.4062 31.4197 29.4062 28.3862C29.4062 25.7361 29.9785 23.3481 31.123 21.2224C32.2649 19.0993 33.7629 17.4476 35.6145 16.265C37.4686 15.0824 39.4244 14.4923 41.487 14.4923C43.0003 14.4923 44.2567 14.7798 45.2562 15.3547C46.2557 15.9296 47.0289 16.6634 47.5757 17.5586C47.446 14.8756 47.301 12.4801 47.1408 10.3721C46.9805 8.26405 46.8661 6.92258 46.8025 6.34766H54.5342V6.34766ZM42.9367 39.4055C44.9662 39.4055 46.4795 38.4145 47.479 36.4351C47.6723 32.7309 47.769 29.0898 47.769 25.5116C47.769 24.1071 47.7359 22.1907 47.6723 19.7624C47.5121 18.7715 47.067 17.8864 46.3422 17.1022C45.6173 16.3205 44.483 15.9296 42.9367 15.9296C40.3908 15.9296 38.6283 17.0391 37.644 19.2581C36.6623 21.4796 36.1715 24.2029 36.1715 27.428C36.1715 31.2608 36.7259 34.2161 37.8373 36.2914C38.9488 38.3666 40.6502 39.4055 42.9367 39.4055Z"
              fill="#70B2CE"/>
            <path
              d="M80.1459 20.7205C79.9526 20.9752 77.7704 22.4049 73.5968 25.0072C69.4258 27.6119 66.0508 29.7124 63.4744 31.3086C64.3773 33.7671 65.6973 35.7314 67.4369 37.2015C69.1765 38.6716 70.9976 39.4054 72.8974 39.4054C74.0902 39.4054 75.0007 39.365 75.6264 39.2843C76.2571 39.2062 76.893 38.9969 77.5364 38.6615C78.1799 38.3261 78.8895 37.7764 79.6626 37.0099L80.1459 37.489C79.566 38.0639 78.9937 38.5758 78.4291 39.0221C77.867 39.4684 76.8039 39.9651 75.2423 40.5073C73.6782 41.0494 71.7707 41.3218 69.5148 41.3218C66.3586 41.3218 63.772 40.7544 61.7577 39.6197C59.7459 38.4875 58.2784 36.9771 57.3603 35.0935C56.4422 33.2098 55.9844 31.1321 55.9844 28.8652C55.9844 26.4066 56.4905 24.0817 57.5053 21.893C58.5201 19.7068 60.0842 17.9266 62.1926 16.5523C64.3036 15.1781 66.9054 14.4922 69.998 14.4922C72.5744 14.4922 74.6523 14.9309 76.2317 15.8085C77.8111 16.6885 78.9123 17.6542 79.5405 18.7083C80.1713 19.7623 80.3722 20.433 80.1459 20.7205ZM73.3806 20.433C73.3806 19.2832 73.0195 18.1661 72.2921 17.0793C71.5698 15.9925 70.321 15.4504 68.5483 15.4504C66.8418 15.4504 65.3667 16.1615 64.1281 17.5811C62.8869 19.0033 62.2664 21.3282 62.2664 24.5533C62.2664 26.5025 62.541 28.3709 63.0878 30.1587C65.6006 28.8803 67.943 27.3875 70.1201 25.6779C72.2921 23.9708 73.3806 22.2208 73.3806 20.433Z"
              fill="#70B2CE"/>
            <path
              d="M97.5426 39.8845C97.5426 40.0433 97.6316 39.7962 97.8071 39.1406C97.9852 38.4875 98.1479 37.378 98.2904 35.8121C98.4353 34.2462 98.5091 32.0903 98.5091 29.3443C98.5091 25.4484 98.3489 22.5335 98.0259 20.5994C97.7029 18.6679 97.2781 17.4071 96.7466 16.8146C96.2125 16.2245 95.5131 15.9295 94.6432 15.9295C92.7434 15.9295 91.019 17.0314 89.4727 19.2353L89.4244 20.7684C89.3608 22.9395 89.3277 24.8407 89.3277 26.4697C89.3277 29.4401 89.3989 31.9238 89.5439 33.9184C89.6889 35.9155 89.8516 37.4562 90.0297 38.543C90.2052 39.6298 90.2942 40.0761 90.2942 39.8845H82.5625C82.6261 39.6298 82.7304 38.9969 82.8753 37.9908C83.0203 36.9846 83.1653 35.5247 83.3102 33.6083C83.4552 31.6919 83.529 29.4729 83.529 26.9488C83.529 24.4247 83.4552 22.2385 83.3102 20.3851C83.1653 18.5318 83.0203 17.1424 82.8753 16.2169C82.7304 15.2915 82.6261 14.7166 82.5625 14.4922H89.8109L89.5693 17.4147C91.4692 15.4655 93.9667 14.4922 97.0594 14.4922C99.1525 14.4922 100.724 15.0041 101.77 16.0253C102.817 17.0465 103.502 18.5166 103.825 20.433C104.148 22.3494 104.308 24.9996 104.308 28.3861C104.308 31.4523 104.379 33.8402 104.524 35.5473C104.669 37.257 104.832 38.4547 105.01 39.1406C105.185 39.829 105.274 40.0761 105.274 39.8845H97.5426Z"
              fill="#70B2CE"/>
          </svg>
        </div>
        {isLoading || isError ? (
          <div className="skeleton">
            <div className="skeleton__row">
              <div className="skeleton__item h1"></div>
              <div className="skeleton__item w100"></div>
              <div className="skeleton__item skeleton__item--mob w100"></div>
              <div className="skeleton__item h2"></div>
            </div>
            <div className="skeleton__list">
              <div className="skeleton__list--item">
                <div className="skeleton__check"></div>
                <div className="skeleton__item w100"></div>
                <div className="skeleton__item skeleton__item--mob w100"></div>
                <div className="skeleton__item l1"></div>
              </div>
              <div className="skeleton__list--item">
                <div className="skeleton__check"></div>
                <div className="skeleton__item w100"></div>
                <div className="skeleton__item skeleton__item--mob w100"></div>
                <div className="skeleton__item l1"></div>
              </div>
              <div className="skeleton__list--item">
                <div className="skeleton__check"></div>
                <div className="skeleton__item w100"></div>
                <div className="skeleton__item skeleton__item--mob w100"></div>
                <div className="skeleton__item l1"></div>
              </div>
              <div className="skeleton__list--item">
                <div className="skeleton__check"></div>
                <div className="skeleton__item w100"></div>
                <div className="skeleton__item skeleton__item--mob w100"></div>
                <div className="skeleton__item l1"></div>
              </div>
            </div>
            <div className="skeleton__row">
              <div className="skeleton__item w100"></div>
              <div className="skeleton__item skeleton__item--mob w100"></div>
              <div className="skeleton__item t1"></div>
            </div>
            <div className="skeleton__row">
              <div className="skeleton__item t2 mob-w100"></div>
              <div className="skeleton__item skeleton__item--mob w100"></div>
              <div className="skeleton__item t3"></div>
            </div>
            <div className="skeleton__row">
              <div className="skeleton__item t4"></div>
            </div>
          </div>
        ) : (
          <>
            <h1 className="info__title">Nous avons une bonne nouvelle! <br />Eden a trouvé comment vous pouvez
              payer <b>-{discountPercent}%</b> moins cher le produit <a href={productUrl}>{title}</a></h1>
            <ul className="info__list">
              {bestOptionUrl && (
                <li><a href={bestOptionUrl}>Cliquez ici</a> pour ouvrir la meilleure offre trouvée par Eden</li>
              )}
              {cashbackPercent && (
                <li>Bénéficiez de <b>{cashbackPercent}% de cashback</b> en cliquant le bouton noir puis "activer le cashback"</li>
              )}
              {giftcardPercent && (
                <li>Payez <b>{giftcardPercent}%</b> moins cher sur zara.com en utilisant une <span>carte cadeau</span></li>
              )}
              {couponCode && (
                <li>Utilisez le coupon <b>“{couponCode}”</b> lors du checkout sur <span>${domain}</span></li>
              )}
            </ul>
            <p className="info__text">Et voilà, en accumulant une ou toutes les étapes ci dessous, vous pouvez <b>économiser
              jusqu'à {discountPercent}%</b> 🥳</p>
            <p className="info__text">Ne perdez pas de temps, les prix et les promotions fluctuent, agissez tant que l'offre
              est valable.</p>
            <p className="info__text">Bon shopping, L'équipe Eden</p>
          </>
        )}
      </div>
    </div>
  );
};

export default InfoBlock;
