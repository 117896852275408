import React from 'react';
import InfoBlock from "../components/InfoBlock";
import PreviewBlock from "../components/PreviewBlock";
import Footer from "../components/Footer";
import '../styles/style.scss';

const Offer = () => {
  return (
    <main className="offer">
      <div className="top">
        <div className="container offer__container">
          <InfoBlock />
          <PreviewBlock />
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Offer;
